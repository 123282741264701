// REACT
import React from "react"
// MUI
import { Typography } from "@material-ui/core"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { ImageBlock } from "../components/imageBlock"
import { ImageWithCaption } from "../components/imageWithCaption"
import { FeaturesList } from "../components/featuresList"
import { SpecificationsTable } from "../components/specificationsTable"

// COMPONENT FUNCTION
const AlcarBoatsFishermanPage = () => {
  // IMAGES
  const data = useStaticQuery(graphql`
    query {
      alcarBoatsFisherman01: file(
        relativePath: { eq: "alcar-boats-fisherman-01.jpg" }
      ) {
        ...BsmFluidImage
      }
      alcarBoatsFisherman02: file(
        relativePath: { eq: "alcar-boats-fisherman-02.jpg" }
      ) {
        ...BsmFluidImage
      }
    }
  `)

  // FEATURES
  const features = [
    "All stainless steel hardware and fastenings",
    "Level foam floatation",
    "One piece tinted safety glass windshield and grab rail",
    "Mooring cleat and chocks",
    "Molded vinyl rubber rail",
    "Navigational and courtesy lighting",
  ]

  // SPECIFICATIONS
  const specifications = {
    "Beam (inches)": [75, 90, 90, 96],
    "Weight (lbs)": [905, 1400, 1600, 2200],
    "Transom Height (inches)": [20, 20, 20, 25],
    "Max HP": [70, 90, 120, 200],
    "Load Capacity (lbs)": [900, 1100, 1350, 2350],
  }

  // COMPONENT
  return (
    <Layout title="Alcar Boats - Fisherman">
      <PageTitle title="ALCAR FISHERMAN" />

      <Typography paragraph>
        Contemporary lines, quality construction, and flawless hand finishing,
        just some of the features of the Alcar Fisherman that will catch your
        eye. It’s now wonder folks from Maine to California depend on Fisherman
        to enjoy just about anything you can do on the water. From zipping along
        the shoreline with a skier in tow, to a pre-dawn fishing trip, to a
        family picnic on a warm summer day, the Fisherman promises endless hours
        of relaxation.
      </Typography>

      <ImageBlock>
        <ImageWithCaption
          fluid={data.alcarBoatsFisherman01.childImageSharp.fluid}
          caption="16' Alcar Fisherman underway"
          displayCaption
          maxWidth={600}
        />
      </ImageBlock>

      <Typography paragraph>
        Coupling the timeless appeal of traditional design with the durability
        of stainless steel railings, welded aluminum windshields, and level
        upright foam floatation, the Fisherman is both stylish and easy to
        maintain. Available in 16’, 17’, 19’, and 21’ models you can customize
        the Fisherman to meet your boating needs. Add a T-top, captain’s chair,
        dive platform, or storage compartments.
      </Typography>

      <Typography paragraph style={{ marginBottom: "4rem" }}>
        With its legendary performance, versatility, easy maintenance, and
        affordability, the Alcar Fisherman is one of the most practical boats on
        the water.
      </Typography>

      <FeaturesList features={features} />

      <ImageBlock>
        <ImageWithCaption
          fluid={data.alcarBoatsFisherman02.childImageSharp.fluid}
          caption="19' Alcar boat in the water"
          displayCaption
          maxWidth={600}
        />
      </ImageBlock>

      <SpecificationsTable
        specifications={specifications}
        header={["", "16'", "17'", "19'", "21'"]}
      />
    </Layout>
  )
}

export default AlcarBoatsFishermanPage
